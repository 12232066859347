.reactour__popover {
    border-radius: 16px 16px 16px 16px;
    padding: 0px !important;
    margin-left: 20px;
    max-width: 1800px !important;
    min-width: 480px !important;
}

.reactour__close-button{
    color: white !important;
    font-weight: 600 !important;
    position: absolute !important;
    right: 0 !important;
    top: 0 !important;
    margin-right: 15px !important;
    margin-top: 15px !important;
}

button[aria-label='Go to prev step'] {
    margin: 0px 0px 15px 15px !important;
}

button[aria-label='Go to next step'] {
    margin: 0px 15px 15px 0px !important;
}

button[aria-label='Go to prev step'] svg,
button[aria-label='Go to next step'] svg {
    width: 30px !important;
    height: 22px !important;
}
