.custom-toast-override {
    width: 550px;
}

@media (max-width: 767px) {
    .custom-toast-override {
        width: 400px;
    }
}

@media (max-width: 520px) {
    .custom-toast-override {
        width: 300px;
    }
}
